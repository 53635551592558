"use strict"

class SBasketService {
    /**
     * Returns the general types for a real payment type, in order such that the
     * first is the highest priority.
     *
     * @param {SPaymentItem["type"]} real_payment_type
     * @returns {generalPaymentType[]}
     */
    static generalTypesFor(real_payment_type) {
        /** @type {Map<generalPaymentType, number>} */
        const general_types = new Map()
        general_types.set(real_payment_type, 1)
        for(const [atype, rtypes] of this.realTypes.entries()) {
            if(rtypes.includes(real_payment_type)) {
                general_types.set(atype, rtypes.length)
            }
        }
        return [...general_types.entries()].sort(
            (a_, b_) => a_[1] - b_[1]
        ).map(i_ => i_[0])
    }
    /**
     * Returns the general types for a general payment type, in order such that
     * the first is the highest priority.
     *
     * @param {generalPaymentType} general_payment_type
     * @returns {generalPaymentType[]}
     */
    static generalTypesForGeneral(general_payment_type) {
        //@ts-ignore
        if(this.realTypes.has(general_payment_type)) {
            return [general_payment_type].concat(
                //@ts-ignore
                this.realTypes.get(general_payment_type)
            )
        } else {
            //@ts-ignore
            return this.generalTypesFor(general_payment_type)
        }
    }
    /**
     * @type {Map<abstractPaymentType, SPaymentItem["type"][]>}
     */
    static get realTypes() {
        const m = new Map()
        m.set("any", ["card", "debit", "paypal", "paystack", "paddle", "transfer", "balance", "razorpay"])
        //m.set("continuousAuthority", ["debit"])
        m.set("immediate", ["card", "paypal", "paystack", "paddle", "balance", "razorpay"])
        return m
    }
    /**
     * @param {generalPaymentType} type
     * @returns {SPaymentItem["type"][]}
     */
    static realTypesFor(type) {
        //@ts-ignore
        return this.realTypes.get(type) || [type]
    }
}
